import React, { ChangeEvent, useState, MouseEvent } from 'react';
import {
  InputBase,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import classnames from 'classnames';
import classes from './InputField.module.scss';
import Text, { Error } from '../Text/Text';

export type InputChangeEvent = ChangeEvent<HTMLInputElement
| HTMLTextAreaElement | HTMLSelectElement>;

type InputFieldProps = {
  label?: string;
  value: string;
  onChange: (event: InputChangeEvent) => void;
  name?: string;
  className?: string;
  currencyAdornment?: boolean;
  disabled?: boolean;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  errorComponent?: string | React.ReactNode;
  showError?: boolean;
  greybox?: boolean;
};

export const TextField: React.FC<InputFieldProps> = ({
  label,
  value,
  onChange,
  name = '',
  className = '',
  currencyAdornment = false,
  disabled = false,
  placeholder = '',
  multiline = false,
  rows = 5,
  showError,
  greybox = false,
  errorComponent,
}) => {
  const composedClassName = classnames(classes['form-control'], className);
  const dollarSignAdornment = currencyAdornment ? <InputAdornment position="start">$</InputAdornment> : null;

  let labelElement;
  if (label) {
    labelElement = (
      <InputLabel
        shrink
        classes={{
          root: classes.label,
          focused: classes.focused,
        }}
      >
        {label}
      </InputLabel>
    );
  }

  const inputClassName = classnames(
    classes.input,
    label ? classes.labelled : '',
    showError ? classes.error : '',
    greybox ? classes.greybox : '',
  );

  const errorContainer = showError && (
    <div className={classes.error__container}>
      <ErrorIcon className={classes.error__icon} color="error" fontSize="inherit" />
      <Text variant={Error.E1} >{errorComponent}</Text>
    </div>
  );

  return (
    <>
    <FormControl classes={{ root: composedClassName }}>
      {labelElement}
      <InputBase
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        value={value}
        onChange={onChange}
        classes={{
          root: inputClassName,
          focused: classes.focused,
          disabled: classes.disabled,
          multiline: classes.multiline,
        }}
        name={name}
        startAdornment={dollarSignAdornment}
        placeholder={placeholder}
      />
    </FormControl>
    {errorContainer}
    </>
  );
};

export const PasswordField: React.FC<InputFieldProps> = ({
  label,
  value,
  onChange,
  name = '',
  className = '',
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = (): void => {
    setPasswordVisible(!passwordVisible);
  };

  const handleMouseDownPassword = (event: MouseEvent): void => {
    event.preventDefault();
  };

  const composedClassName = classnames(classes['form-control'], className);

  const inputType = passwordVisible ? 'text' : 'password';
  const visibilityIconProps = {
    classes: {
      root: classes['password-adornment'],
    },
  };
  const visibilityIcon = passwordVisible
    ? <Visibility {...visibilityIconProps} />
    : <VisibilityOff {...visibilityIconProps} />;
  const visibilityAdornment = (
    <InputAdornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        onClick={handleTogglePasswordVisibility}
        onMouseDown={handleMouseDownPassword}
      >
        {visibilityIcon}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormControl classes={{ root: composedClassName }}>
      <InputLabel
        shrink
        classes={{
          root: classes.label,
          focused: classes.focused,
        }}
      >
        {label}
      </InputLabel>
      <InputBase
        value={value}
        onChange={onChange}
        classes={{
          root: classes.input,
          focused: classes.focused,
        }}
        type={inputType}
        endAdornment={visibilityAdornment}
        name={name}
      />
    </FormControl>
  );
};
