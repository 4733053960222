import i18next from 'i18next';
import { getServerUrl } from '../../lib/utils';
import { ConfigurationPayload } from './types';

const GET_CONFIGURATION_ERROR_MESSAGE = i18next.t('get_configuration_default_error_message');
const UPDATE_CONFIGURATION_ERROR_MESSAGE = i18next.t('update_configuration_default_error_message');

export const getConfiguration = async (): Promise<ConfigurationPayload> => {
  const res = await fetch(`${getServerUrl()}/configuration`, {
    credentials: 'include',
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message || GET_CONFIGURATION_ERROR_MESSAGE);
  }

  return data;
};

export const updateConfiguration = async (
  payload: Partial<ConfigurationPayload>,
): Promise<ConfigurationPayload> => {
  const res = await fetch(`${getServerUrl()}/configuration`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message || UPDATE_CONFIGURATION_ERROR_MESSAGE);
  }

  return data;
};
