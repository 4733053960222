// eslint-disable-next-line import/prefer-default-export
export enum HomePageView {
  overview = 'overview',
  users = 'users',
  configuration = 'configuration',
  documentation = 'documentation',
}

export type HomeRouterParams = {
  appId: string;
  serviceName: string;
  tabName?: HomePageView;
};
