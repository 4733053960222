import {
  useReducer, useEffect, Reducer, useState, Dispatch,
} from 'react';

/**
 * @type {Action}
 * This type will represent the
 * action our reducer takes.
 * It has three params the action
 * `type`, the payload we expect
 *  to receive from the endpoint
 *  and the error message
 */

type Action<P> =
  | { type: 'FETCH_INIT' }
  | {
    type: 'FETCH_SUCCESS';
    payload: P;
  }
  | {
    type: 'FETCH_FAILURE';
    message: string;
  };

/**
 *
 * @type {State}
 * This type is the initial
 * state our reducer expects.
 * It hold all the possible
 * states our app can be in
 * durning the fetch.
 */

type State<P> = {
  loading: boolean;
  data: null | P;
  error: undefined | string;
};

/**
 * @function dataFetchReducer
 * Our fetch reducer
 */
const dataFetchReducer = <P>(state: State<P>, action: Action<P>): State<P> => {
  switch (action.type) {
    // The initial loading state
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
      };
    // We successfully received the data
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload,
      };
    // The fetch failed
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    /**
     * If we don't receive an expected action
     * we assume it's a developer mistake
     * and we will throw an error asking them
     * to fix that
     */
    default:
      throw new Error(
        `Unknown action type received 
        for dataFetchReducer.
        Please make sure you are passing
        one of the following actions:
          * FETCH_INIT
          * FETCH_SUCCESS
          * FETCH_FAILURE
          :`,
      );
  }
};

type UseFetchResult<TData, TPatchPayload> = State<TData> & {
  refetch: () => void;
  update?: (payload: TPatchPayload) => Promise<void>;
  remove?: (id: number) => Promise<void>;
};

const fetchData = async <TData>(
  get: () => Promise<TData>, dispatch: Dispatch<Action<TData>>,
): Promise<void> => {
  try {
    dispatch({ type: 'FETCH_INIT' });
    const data = await get();
    dispatch({ type: 'FETCH_SUCCESS', payload: data });
  } catch (err) {
    dispatch({ type: 'FETCH_FAILURE', message: err.message });
  }
};

const updateData = async <TData, TPatchData, TPatchPayload>(
  patch: (payload: TPatchPayload) => Promise<TPatchData>,
  payload: TPatchPayload,
  state: State<TData>,
  dispatch: Dispatch<Action<TData>>,
): Promise<void> => {
  try {
    dispatch({ type: 'FETCH_INIT' });
    await patch(payload);
    /**
     * If state data is not defined then we do not want to overwrite the state with
     * a partial of that state
     */
    if (!state.data) {
      return;
    }
    dispatch({ type: 'FETCH_SUCCESS', payload: state.data });
  } catch (err) {
    dispatch({ type: 'FETCH_FAILURE', message: err.message });
  }
};

const deleteData = async <TData>(
  deleteMethod: (id: number) => Promise<void>,
  id: number,
  state: State<TData>,
  dispatch: Dispatch<Action<TData>>,
): Promise<void> => {
  try {
    dispatch({ type: 'FETCH_INIT' });
    await deleteMethod(id);
    if (!state.data) {
      return;
    }
    dispatch({ type: 'FETCH_SUCCESS', payload: state.data });
  } catch (err) {
    dispatch({ type: 'FETCH_FAILURE', message: err.message });
  }
};

const useFetch = <
  TData, TPatchPayload = Partial<TData>, TPatchData = TPatchPayload
>(
    get: () => Promise<TData>,
    patch?: (payload: TPatchPayload) => Promise<TPatchData>,
    deleteMethod?: (id: number) => Promise<void>,
  ): UseFetchResult<TData, TPatchPayload> => {
  const initialState = {
    loading: false,
    data: null,
    error: undefined,
  };
  const [state, dispatch] = useReducer<Reducer<State<TData>, Action<TData>>>(
    dataFetchReducer,
    initialState,
  );
  const [reload, setReload] = useState(0);

  let update;
  // If include update is true, we provide update function
  if (patch) {
    update = async (
      payload: TPatchPayload,
    ): Promise<void> => {
      await updateData<TData, TPatchData, TPatchPayload>(patch, payload, state, dispatch);
      setReload(reload + 1);
    };
  }

  let remove;
  if (deleteMethod) {
    remove = async (
      id: number,
    ): Promise<void> => {
      await deleteData<TData>(deleteMethod, id, state, dispatch);
      setReload(reload + 1);
    };
  }

  useEffect(() => {
    fetchData<TData>(get, dispatch);
  }, [get, reload]);

  return {
    ...state, refetch: (): void => setReload(reload + 1), update, remove,
  };
};

export default useFetch;
