import i18next from 'i18next';
import { getServerUrl } from '../../../lib/utils';
import { AccountInvitation } from './types';

const GET_ACCOUNT_INVITATIONS_ERROR_MESSAGE = i18next.t('get_account_invitations_error_message');
const UPDATE_ACCOUNT_INVITATIONS_ERROR_MESSAGE = i18next.t('update_account_invitations_error_message');

export async function createOrUpdateAccountInvitation(payload: {
  senderFirstName: string;
  senderLastName: string;
  senderEmail: string;
  email: string;
}): Promise<void> {
  const res = await fetch(`${getServerUrl()}/accountInvitations`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!res.ok) {
    const data = await res.json();
    throw new Error(data.message || UPDATE_ACCOUNT_INVITATIONS_ERROR_MESSAGE);
  }
}

export async function getAccountInvitations(): Promise<AccountInvitation[]> {
  const res = await fetch(`${getServerUrl()}/accountInvitations`, {
    credentials: 'include',
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message || GET_ACCOUNT_INVITATIONS_ERROR_MESSAGE);
  }

  return data;
}

export async function deleteAccountInvitation(id: number): Promise<void> {
  const res = await fetch(`${getServerUrl()}/accountInvitations/${id}`, {
    credentials: 'include',
    method: 'DELETE',
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message || GET_ACCOUNT_INVITATIONS_ERROR_MESSAGE);
  }
}
