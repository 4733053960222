import Text, {
  Paragraph, Caption, Label, Subtitle, Heading,
} from './Text';

export default Text;
export {
  Paragraph,
  Caption,
  Label,
  Subtitle,
  Heading,
};
