import { ConfigurationPayload, ConfigurationPayloadKey } from '../../../../modules/configuration/types';

// eslint-disable-next-line import/prefer-default-export

export const isStateModified = (
  state: ConfigurationPayload, 
  configuration: ConfigurationPayload, 
  keys: ConfigurationPayloadKey[],
): boolean => {
  let modified = false;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const stateValue = state[key];
    const configValue = configuration[key];

    if(stateValue && configValue) {
      if(stateValue.value !== configValue.value) {
        modified = true;
      }
    } else if(!stateValue && configValue){
      modified = true;
    } else if(stateValue && !configValue){
      modified = true;
    }

    if(modified) {
      break;
    }
  }
  return modified;
}
