import React from 'react';
import classnames from 'classnames';
import { HelpOutlineRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../atoms';
import { InputChangeEvent } from '../../../../atoms/InputField/InputField';
import Text, { Caption } from '../../../../atoms/Text';
import { AuthenticationMethodType } from '../../../../molecules/AuthenticationMethod';
import AuthenticationMethodAccordion from '../AuthenticationMethodAccordion';

import classes from './AppleConfiguration.module.scss';
import { AppleAuthConfiguration, ConfigurationPayloadKey } from '../../../../../modules/configuration/types';

const AppleConfiguration: React.FC<{
  configuration: AppleAuthConfiguration;
  onChangeInput: (e: InputChangeEvent, key: ConfigurationPayloadKey) => void;
  className?: string;
  isModified?: boolean;
  onSave: (payload: Partial<AppleAuthConfiguration>) => Promise<void>;
  onDiscard: () => void;
}> = ({
  configuration, onChangeInput, className, isModified = false, onSave, onDiscard,
}) => {
  const { t } = useTranslation();

  const handleSaveConfiguration = async (): Promise<void> => {
    const {
      appleAuthClientId, appleAuthName, appleAuthSigningKey, appleAuthTeamId, appleAuthSigningKeyId,
    } = configuration;
    await onSave({
      appleAuthClientId,
      appleAuthName,
      appleAuthSigningKey,
      appleAuthTeamId,
      appleAuthSigningKeyId,
    });
  };

  return (
      <AuthenticationMethodAccordion
        className={classnames(className, classes['apple-auth'])}
        method={AuthenticationMethodType.apple}
        isModified={isModified}
        onSave={handleSaveConfiguration}
        onDiscard={onDiscard}
      >
        <div className={classes.input__container}>
          <TextField
            label={t('name')}
            value={configuration?.appleAuthName.value || ''}
            onChange={(e: InputChangeEvent): void => onChangeInput(e, 'appleAuthName')}
            placeholder='apple'
          />
        </div>
        <div className={classes.input__container}>
          <TextField
            label={t('client_id')}
            value={configuration?.appleAuthClientId.value || ''}
            onChange={(e: InputChangeEvent): void => onChangeInput(e, 'appleAuthClientId')}
          />
          <Text className={classes['client-id__caption']} variant={Caption.C2}>
            <HelpOutlineRounded />
            {t('how_to_client_id')}
          </Text>
        </div>
        <div className={classes.input__container}>
          <TextField
            label={t('client_secret_signing_key')}
            value={configuration?.appleAuthSigningKey.value || ''}
            multiline={true}
            rows={5}
            onChange={(e: InputChangeEvent): void => onChangeInput(e, 'appleAuthSigningKey')}
          />
          <Text className={classes['client-id__caption']} variant={Caption.C2}>
            <HelpOutlineRounded />
            {t('how_to_client_signing_key')}
          </Text>
        </div>
        <div className={classes.input__container}>
          <TextField
            label={t('apple_team_id')}
            value={configuration?.appleAuthTeamId.value || ''}
            onChange={(e: InputChangeEvent): void => onChangeInput(e, 'appleAuthTeamId')}
          />
          <Text className={classes['client-id__caption']} variant={Caption.C2}>
            <HelpOutlineRounded />
            {t('how_to_apple_team_id')}
          </Text>
        </div>
        <div className={classes.input__container}>
          <TextField
            label={t('key_id')}
            value={configuration?.appleAuthSigningKeyId.value || ''}
            onChange={(e: InputChangeEvent): void => onChangeInput(e, 'appleAuthSigningKeyId')}
          />
          <Text className={classes['client-id__caption']} variant={Caption.C2}>
            <HelpOutlineRounded />
            {t('how_to_key_id')}
          </Text>
        </div>
      </AuthenticationMethodAccordion>
  );
};

export default AppleConfiguration;
