import useFetch from '../../lib/useFetch';
import { getAccounts, updateAccount } from './api';
import { Account } from './types';

type UseAccountsResult = {
  loading: boolean;
  error: undefined | string;
  data: Account[] | null;
  refetch: () => void;
  update: (payload: Partial<Account>) => Promise<void>;
};

const useAccounts = (): UseAccountsResult => {
  const state = useFetch<Account[], Partial<Account>>(getAccounts, updateAccount);
  const update = state.update as (payload: Partial<Account>) => Promise<void>;
  return { ...state, update };
};

export default useAccounts;
