import {
  IS_DEV, IDENTITY_API_URL,
} from '../config';
import { isAxiosError } from './axios';
import { ApiError, ServiceErrorResponse } from './types';

const serverUrlRegex = /^\/([a-zA-Z0-9]*)\/([a-zA-Z0-9-]*).?/i;

export const extractServiceDetails = (url: string): {
  appId?: string;
  serviceName?: string;
} => {
  const results = serverUrlRegex.exec(url);
  if (results) {
    const appId = results[1];
    const serviceName = results[2];
    return {
      appId,
      serviceName,
    };
  }
  return {};
};

export const getServerUrl = (): string => {
  if (IS_DEV) {
    return IDENTITY_API_URL;
  }

  const { appId, serviceName } = extractServiceDetails(window.location.pathname);
  return `${IDENTITY_API_URL}/${appId}/${serviceName}`;
};

export const getBasePath = (): string => {
  const { appId, serviceName } = extractServiceDetails(window.location.pathname);
  return `/${appId}/${serviceName}`;
};

export const toApiError = (error: Error): Error => {
  if(isAxiosError(error) && error.response) {
    const errorResponse: ServiceErrorResponse | undefined =  error.response.data;
    return new ApiError({
      code: errorResponse?.code || error.response.status,
      type: errorResponse?.type || error.response.statusText,
      message: errorResponse?.message || error.message,
    });
  } 
  return error;
}