import React from 'react';
import classnames from 'classnames';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';

import classes from './Checkbox.module.scss';

type CheckboxProps = {
  checked: boolean;
  name: string;
  onChange: () => void;
  label?: string;
  className?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked, name, onChange, label, className,
}) => (
  <FormControlLabel
    className={classnames(className, classes.checkbox)}
    control={
      <MuiCheckbox
        classes={{
          root: classes.checkbox,
          checked: classes.checked,
        }}
        checked={checked}
        onChange={onChange}
        name={name}
      />
    }
    label={label}
  />
);

export default Checkbox;
