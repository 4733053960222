import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import AuthenticationMethodAccordion from '../AuthenticationMethodAccordion';
import { AuthenticationMethodType } from '../../../../molecules/AuthenticationMethod';
import { Checkbox } from '../../../../atoms';
import { PasswordAuthConfiguration, ConfigurationPayloadKey } from '../../../../../modules/configuration/types';

import classes from './PasswordConfiguration.module.scss';

const PasswordConfiguration: React.FC<{
  onChange: (key: ConfigurationPayloadKey, value: boolean) => void;
  configuration: PasswordAuthConfiguration;
  className?: string;
}> = ({ onChange, configuration, className }) => {
  const { t } = useTranslation();
  const { passwordAuthReset, verifyEmailEnabled } = configuration;

  return (
    <AuthenticationMethodAccordion
      className={classnames(classes['password-auth'], className)}
      method={AuthenticationMethodType.password}
    >
    <Checkbox
      name='passwordAuthReset'
      label={t('allow_reset_password')}
      checked={!passwordAuthReset.value}
      onChange={(): void => onChange('passwordAuthReset', !passwordAuthReset.value)}
    />
    <Checkbox
      name='verifyEmailEnabled'
      label={t('verify_email')}
      checked={verifyEmailEnabled.value}
      onChange={(): void => onChange('verifyEmailEnabled', !verifyEmailEnabled.value)}
    />
  </AuthenticationMethodAccordion>
  );
};

export default PasswordConfiguration;
