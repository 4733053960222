import React from 'react';
import { Tabs as MuiTabs } from '@material-ui/core';
import classnames from 'classnames';

import classes from './Tabs.module.scss';

const Tabs: React.FC<{
  onChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
  value: number | string;
  className?: string;
  'aria-label'?: string;
}> = ({
  children,
  className,
  ...props
}) => (
  <MuiTabs
    {...props}
    classes={{
      root: classnames(classes.tabs, className),
      indicator: classes.indicator,
    }}
  >
    {children}
  </MuiTabs>
);

export default Tabs;
