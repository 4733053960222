import React, { useState, useContext } from 'react';
import { AddRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserList } from '../../../molecules';
import ListTemplate from '../../../organisms/ListTemplate';
import { isAccountInvitation } from '../../../molecules/UserList/utils';
import classes from './Users.module.scss';
import {
  Text, TextField, InputChangeEvent,
} from '../../../atoms';
import ModalConfirmation from '../../../organisms/ModalConfirmation';
import { AuthContext } from '../../../../modules/auth';
import { Account } from '../../../../modules/account/types';
import { AccountInvitation } from '../../../../modules/account/invitation/types';
import { resetPassword } from '../../../../modules/account/api';
import useAccounts from '../../../../modules/account/useAccounts';
import useAccountInvitations from '../../../../modules/account/invitation/useAccountInvitations';

enum ModalState {
  reset = 'reset',
  invite = 'invite',
  delete = 'delete',
  resend = 'resend',
  closed = 'closed',
}

const Users: React.FC<{ configurationUrl: string }> = ({ configurationUrl }) => {
  const {
    error: usersError,
    data: users,
    refetch: refetchUsers,
    update: updateUser,
  } = useAccounts();
  const {
    error: accountInvitationsError,
    data: accountInvitations,
    update: updateAccountInvitation,
    remove: deleteAccountInvitation,
  } = useAccountInvitations();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [selectedAccount, setSelectedUser] = useState<Account | AccountInvitation>();
  const [modalOpen, setModalOpen] = useState<ModalState>(ModalState.closed);
  const [resetPasswordError, setResetPasswordError] = useState<string>();
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const { appDetailsState } = useContext(AuthContext);
  const { data } = appDetailsState;

  if (usersError || accountInvitationsError || !users || !accountInvitations) {
    return <div>{usersError || accountInvitationsError || 'Error...'}</div>;
  }

  const handleToggleStatus = async (enabled: boolean): Promise<void> => {
    if (selectedAccount && !isAccountInvitation(selectedAccount)) {
      const { id } = selectedAccount;
      await updateUser({ id, enabled });
      refetchUsers();
    }
  };

  const confirmResetPassword = (): void => {
    setModalOpen(ModalState.reset);
  };

  const confirmDeleteInvite = (): void => {
    setModalOpen(ModalState.delete);
  };

  const confirmResendInvite = (): void => {
    setModalOpen(ModalState.resend);
  };

  const handleResetPassword = async (): Promise<void> => {
    try {
      if (selectedAccount) {
        await resetPassword(selectedAccount.id);
      }
      setModalOpen(ModalState.closed);
    } catch (e) {
      setResetPasswordError(e.message);
    }
  };

  const handleEmailChange = ({ target: { value } }: InputChangeEvent): void => {
    setEmail(value);
    setShowEmailError(false);
  };

  const validateEmail = (): boolean => {
    const regexp = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');
    return regexp.test(email);
  };

  const handleInviteUser = async (): Promise<void> => {
    if (!validateEmail()) {
      setShowEmailError(true);
      return;
    }
    if (data?.account) {
      const {
        firstName: senderFirstName,
        lastName: senderLastName,
        email: senderEmail,
      } = data.account;
      await updateAccountInvitation({
        senderFirstName, senderLastName, senderEmail, email,
      });
    }
    setModalOpen(ModalState.closed);
  };

  const handleDeleteInvite = (): void => {
    if (selectedAccount && isAccountInvitation(selectedAccount)) {
      deleteAccountInvitation(selectedAccount.id);
      setModalOpen(ModalState.closed);
    }
  };

  const handleResendInvite = async (): Promise<void> => {
    if (data?.account && selectedAccount && isAccountInvitation(selectedAccount)) {
      const {
        firstName: senderFirstName,
        lastName: senderLastName,
        email: senderEmail,
      } = data.account;
      await updateAccountInvitation({
        senderFirstName, senderLastName, senderEmail, email: selectedAccount.email,
      });
    }
    setModalOpen(ModalState.closed);
  };

  const closeResetModal = (): void => {
    setModalOpen(ModalState.closed);
    setResetPasswordError(undefined);
  };

  const closeModal = (): void => {
    setModalOpen(ModalState.closed);
  };

  const buttonProps = {
    label: (
      <>
        <AddRounded />
        {t('invite_user')}
      </>
    ),
    onClick: (): void => setModalOpen(ModalState.invite),
  };

  let confirmationModalContent;
  if (selectedAccount && !isAccountInvitation(selectedAccount)) {
    switch (true) {
      case resetPasswordError === 'Password reset has been disabled':
        confirmationModalContent = (
          <Text>
            {t('password_reset_disabled')}
            <Link to={configurationUrl}>
              {t('go_to_configuration')}
            </Link>
          </Text>
        );
        break;
      case resetPasswordError === 'No password credentials exist':
        confirmationModalContent = (
          <Text>
            {t('social_authentication_method_used', selectedAccount)}
          </Text>
        );
        break;
      case resetPasswordError !== undefined:
        confirmationModalContent = <Text>{resetPasswordError}</Text>;
        break;
      default:
        confirmationModalContent = <Text>{t('send_password_link_to', selectedAccount)}</Text>;
        break;
    }
  }

  const inviteUserContent = (
    <>
      <Text>{t('invite_user_message')}</Text>
      <TextField
        label={t('invite_user_email_header')}
        name='email'
        value={email}
        onChange={handleEmailChange}
        className={classes.email}
        errorComponent={'Please enter a valid email address'}
        showError={showEmailError}
        greybox={true}
      />
    </>
  );

  const deleteInviteContent = (
    <Text>{t('delete_invite_message')}</Text>
  );

  let resendInviteContent;
  if (selectedAccount && isAccountInvitation(selectedAccount)) {
    resendInviteContent = <Text>{t('resend_invite_message', selectedAccount)}</Text>;
  }

  let visibleModal;

  switch (modalOpen) {
    case ModalState.reset:
      visibleModal = (
        <ModalConfirmation
          open={modalOpen === ModalState.reset}
          title={t('reset_user_password')}
          confirmText={resetPasswordError ? t('close') : t('send_reset_link')}
          showCancel={!resetPasswordError}
          onConfirm={resetPasswordError ? closeResetModal : handleResetPassword}
          onClose={closeResetModal}>
          {confirmationModalContent}
        </ModalConfirmation>
      );
      break;
    case ModalState.invite:
      visibleModal = (
        <ModalConfirmation
          open={modalOpen === ModalState.invite}
          title={t('invite_user')}
          confirmText={t('invite_user')}
          onConfirm={handleInviteUser}
          onClose={closeModal}>
          {inviteUserContent}
        </ModalConfirmation>
      );
      break;
    case ModalState.delete:
      visibleModal = (
        <ModalConfirmation
          open={modalOpen === ModalState.delete}
          title={t('delete_user_invite')}
          confirmText={t('delete_invite_confirm')}
          onConfirm={handleDeleteInvite}
          variant={'danger'}
          showCancel={true}
          onClose={closeModal}>
          {deleteInviteContent}
        </ModalConfirmation>
      );
      break;
    case ModalState.resend:
      visibleModal = (
        <ModalConfirmation
          open={modalOpen === ModalState.resend}
          title={t('resend_user_invite')}
          confirmText={t('resend_invite')}
          onConfirm={handleResendInvite}
          showCancel={true}
          onClose={closeModal}>
          {resendInviteContent}
        </ModalConfirmation>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <ListTemplate className={classes.users} title={t('users').toUpperCase()} button={buttonProps}>
        <UserList
          users={users}
          userInvites={accountInvitations}
          selectedUser={selectedAccount}
          onSelectUser={setSelectedUser}
          onResetPassword={confirmResetPassword}
          onToggleEnabled={handleToggleStatus}
          onDeleteInvite={confirmDeleteInvite}
          onResendInvite={confirmResendInvite}
        />
      </ListTemplate>
      {visibleModal}
    </>
  );
};

export default Users;
