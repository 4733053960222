import React from 'react';
import classnames from 'classnames';
import { Button } from '../../atoms';
import Text, { Caption } from '../../atoms/Text';

import classes from './ListTemplate.module.scss';

type ListTemplateProps = {
  button: {
    label: string | React.ReactElement;
    onClick: () => void;
  };
  title: string;
  className?: string;
};

const ListTemplate: React.FC<ListTemplateProps> = ({
  button: {
    label,
    onClick,
  },
  title,
  className,
  children,
}) => (
  <div className={classnames(classes['list-template'], className)}>
    <div className={classes.title__container}>
      <Text variant={Caption.C1}>{title}</Text>
      <Button onClick={onClick}>
        {label}
      </Button>
    </div>
    {children}
  </div>
);

export default ListTemplate;
