import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ConfigurationContainer from '../../organisms/ConfigurationContainer';
import Configuration from './Configuration';
import Overview from './Overview';
import { HomeRouterParams } from './types';
import Users from './Users';

const HomeRouter: React.FC<{}> = () => {
  const { params } = useRouteMatch<HomeRouterParams>();
  const { appId, serviceName } = params;
  const url = `/${appId}/${serviceName}`;

  return (
    <>
      <Switch>
        <Route exact path={`${url}/`} >
          <ConfigurationContainer component={Overview} path={`${url}/configuration`} />
        </Route>
        <Route exact path={`${url}/users`} >
          <Users configurationUrl={`${url}/configuration`} />
        </Route>
        <Route exact path={`${url}/configuration`}>
          <ConfigurationContainer component={Configuration} path={`${url}/configuration`} />
        </Route>
        <Route exact path={`${url}/documentation`} />
      </Switch>
    </>
  );
};

export default HomeRouter;
