import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Text, TextField } from '../../../../atoms';
import { Label } from '../../../../atoms/Text';
import { InputChangeEvent } from '../../../../atoms/InputField/InputField';

import classes from './ApplicationUrlConfiguration.module.scss';
import { UrlConfiguration, ConfigurationPayloadKey } from '../../../../../modules/configuration/types';

const ApplicationUrlConfiguration: React.FC<{
  configuration: UrlConfiguration;
  onChangeInput: (e: InputChangeEvent, key: ConfigurationPayloadKey) => void;
  isModified?: boolean;
  onSave: (payload: Partial<UrlConfiguration>) => Promise<void>;
  onDiscard: () => void;
}> = ({
  configuration, onChangeInput, isModified = false, onSave, onDiscard,
}) => {
  const { t } = useTranslation();
  const handleSaveConfiguration = async (): Promise<void> => {
    const {
      signUpUrl, resetPasswordUrl, completionFormUrl, ssoErrorUrl, emailVerificationUrl,
    } = configuration;
    await onSave({
      signUpUrl,
      resetPasswordUrl,
      completionFormUrl,
      ssoErrorUrl,
      emailVerificationUrl,
    });
  };

  let buttons;

  if (isModified) {
    buttons = (
      <div className={classes.button__container}>
        <Button onClick={handleSaveConfiguration} size='sm'>{t('save')}</Button>
        <Button variant='grey' onClick={onDiscard} size='sm'>{t('discard')}</Button>
      </div>
    );
  }

  return (
    <div>
      <Text className={classes['application-urls__label']} variant={Label.L1} underlined>
        {t('application_urls')}
      </Text>
      <TextField
        className={classnames(classes['sign-up-url__input'], classes.input)}
        onChange={(e: InputChangeEvent): void => onChangeInput(e, 'signUpUrl')}
        value={configuration?.signUpUrl.value || ''}
        label={t('sign_up_url')}
      />
      <TextField
        className={classnames(classes['reset-password-url__input'], classes.input)}
        onChange={(e: InputChangeEvent): void => onChangeInput(e, 'resetPasswordUrl')}
        value={configuration?.resetPasswordUrl.value || ''}
        label={t('reset_password_url')}
      />
      <TextField
        className={classnames(classes['sso-error-url__input'], classes.input)}
        onChange={(e: InputChangeEvent): void => onChangeInput(e, 'ssoErrorUrl')}
        value={configuration?.ssoErrorUrl.value || ''}
        label={t('sso_error_url')}
      />
      <TextField
        className={classnames(classes['completion-form-url__input'], classes.input)}
        onChange={(e: InputChangeEvent): void => onChangeInput(e, 'completionFormUrl')}
        value={configuration?.completionFormUrl.value || ''}
        label={t('completion_form_url')}
      />
      <TextField
        className={classnames(classes['email-verification-url__input'], classes.input)}
        onChange={(e: InputChangeEvent): void => onChangeInput(e, 'emailVerificationUrl')}
        value={configuration?.emailVerificationUrl.value || ''}
        label={t('email_verification_url')}
      />
      {buttons}
    </div>
  );
};

export default ApplicationUrlConfiguration;
