import React from 'react';
import classnames from 'classnames';
import { ExpandMoreRounded } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AuthenticationMethod } from '../../../../molecules';
import { AuthenticationMethodType } from '../../../../molecules/AuthenticationMethod';
import Text, { Subtitle } from '../../../../atoms/Text';
import { Button } from '../../../../atoms';

import classes from './AuthenticationMethodAccordion.module.scss';

enum DisableMethodKey {
  password = 'disable_email',
  google = 'disable_google',
  facebook = 'disable_facebook',
  apple = 'disable_apple',
  auth0 = 'disable_auth0'
}

export type AuthenticationMethodAccordionProps = {
  className?: string;
  method: AuthenticationMethodType;
  isModified?: boolean;
  onSave?: () => void | Promise<void>;
  onDiscard?: () => void | void;
};

const AuthenticationMethodAccordion: React.FC<AuthenticationMethodAccordionProps> = ({
  method, children, className, onSave, onDiscard, isModified = false,
}) => {
  const { t } = useTranslation();

  let buttons;
  if (isModified) {
    buttons = (
      <div className={classes.button__container}>
        <Button onClick={onSave} size='sm'>{t('save')}</Button>
        <Button variant='grey' onClick={onDiscard} size='sm'>{t('discard')}</Button>
      </div>
    );
  }

  return (
    <>
      <Accordion className={classnames(className, classes['authentication-method-accordion'])}>
        <AccordionSummary
          classes={{
            root: classes.configuration__control,
            content: classes['control-content__container'],
            expandIcon: classes.expand__icon,
            expanded: classes.expanded,
          }}
          expandIcon={<ExpandMoreRounded />}
          aria-controls={`${method}-content`}
        >
          <AuthenticationMethod method={method} />
        </AccordionSummary>
        <AccordionDetails className={classes['configuration-details']}>
          <div className={classes['configuration-details__container']}>
            {children}
            <div className={classes.disable__container}>
              <Text variant={Subtitle.S2}>{t(DisableMethodKey[method])}</Text>
              <Button outlined size='sm' variant='danger'>{t('disable')}</Button>
            </div>
            {buttons}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AuthenticationMethodAccordion;
