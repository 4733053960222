import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '../../atoms';
import { Heading } from '../../atoms/Text';
import { Modal } from '../../molecules';

import classes from './ModalConfirmation.module.scss';

type ModalConfirmationProps = {
  open: boolean;
  title: string;
  variant?: 'grey' | 'brand' | 'danger' | 'success' | 'warning' | 'info' | 'text';
  confirmText: string;
  showCancel?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
};

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  title,
  variant,
  children,
  confirmText,
  showCancel,
  open,
  onClose,
  onConfirm = (): void => { },
}) => {
  const { t } = useTranslation();

  let cancelButton;
  if (showCancel) {
    cancelButton = (
      <Button variant={'grey'} onClick={onClose} className={classes.cancel_button}>
        {t('cancel')}
      </Button>
    );
  }

  return (
    <Modal open={open} onClose={onClose} >
      <div className={classes.container}>
        <Text variant={Heading.H4} className={classes.padding}>{title}</Text>
        <div className={classes.content_margin}>
          {children}
        </div>
        <div className={classes.divider}></div>
        <Button
          variant={variant}
          onClick={onConfirm}
          className={classes.confirm_button}>
          {confirmText}
        </Button>
        {cancelButton}
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
