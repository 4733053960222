import i18next from 'i18next';
import { getServerUrl } from '../../lib/utils';
import { Account } from './types';

const GET_USER_ERROR_MESSAGE = i18next.t('get_user_default_error_message');
const GET_USERS_ERROR_MESSAGE = i18next.t('get_users_default_error_message');
const UPDATE_USER_ERROR_MESSAGE = i18next.t('update_user_default_error_message');
const RESET_PASSWORD_ERROR_MESSAGE = i18next.t('reset_password_error_message');

export async function getAccount(id: string): Promise<Account> {
  const res = await fetch(`${getServerUrl()}/accounts/${id}`, {
    credentials: 'include',
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message || GET_USER_ERROR_MESSAGE);
  }

  return data;
}

export async function updateAccount(payload: Partial<Account>): Promise<Account> {
  const res = await fetch(`${getServerUrl()}/accounts/${payload.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message || UPDATE_USER_ERROR_MESSAGE);
  }

  return data;
}

export async function getAccounts(): Promise<Account[]> {
  const res = await fetch(`${getServerUrl()}/accounts`, {
    credentials: 'include',
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message || GET_USERS_ERROR_MESSAGE);
  }

  return data;
}

export async function resetPassword(id: number): Promise<void> {
  const res = await fetch(`${getServerUrl()}/accounts/${id}/actions/resetPassword`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    const data = await res.json();
    throw new Error(data.message || RESET_PASSWORD_ERROR_MESSAGE);
  }
}
