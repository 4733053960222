import React, { useEffect, useState } from 'react';
import { AddRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '../../../atoms';
import Text, { Heading, Label, Subtitle } from '../../../atoms/Text';
import { InputChangeEvent } from '../../../atoms/InputField/InputField';
import GoogleConfiguration from './GoogleConfiguration';
import AppleConfiguration from './AppleConfiguration';
import FacebookConfiguration from './FacebookConfiguration';
import PasswordConfiguration from './PasswordConfiguration';
import ApplicationUrlConfiguration from './ApplicationUrlConfiguration';
import ApplicationTokenConfiguration from './ApplicationTokenConfiguration';
import {
  isStateModified,
} from './utils';
import { ConfigurationPayload, ConfigurationPayloadKey } from '../../../../modules/configuration/types';

import classes from './Configuration.module.scss';
import Auth0Configuration from './Auth0Configuration';

const Configuration: React.FC<{
  error?: string;
  configuration: ConfigurationPayload | null;
  updateConfigurations: (payload: Partial<ConfigurationPayload>) => Promise<void>;
  onOpenModal: () => void;
}> = ({
  error, configuration, updateConfigurations, onOpenModal,
}) => {
  const { t } = useTranslation();

  const [state, setState] = useState<ConfigurationPayload>();
  const [showTokenError, setTokenError] = useState<boolean>(false);
  const [showRefreshTokenError, setRefreshTokenError] = useState<boolean>(false);

  useEffect(() => {
    /**
       * Wait until configuration has been fetched, we only want to set
       * the state if it has not already been set
       */
    if (configuration && !state) {
      setState(configuration);
    }
  }, [configuration, state]);

  if (error || !configuration) {
    return <div>Error...</div>;
  }

  const handleChangeConfiguration = (
    key: ConfigurationPayloadKey, value: string | boolean | number,
  ): void => {
    const currentState = state as ConfigurationPayload;
    setState({ ...currentState, [key]: { ...currentState[key], value } });
    updateConfigurations({ [key]: { id: configuration[key].id, value } });
  };

  const handleChangeInput = (e: InputChangeEvent, key: ConfigurationPayloadKey): void => {
    const currentState = state as ConfigurationPayload;
    setState({ ...currentState, [key]: { ...currentState[key], value: e.currentTarget.value } });
  };

  const handleChangeInputNumber = (
    e: InputChangeEvent,
    key: ConfigurationPayloadKey,
  ): void => {
    const checkForNumber = /^[0-9\b]+$/;
    const currentState = state as ConfigurationPayload;
    const inputNumber = e.currentTarget.value;
    if (key === 'tokenExpiry') {
      if (Number(inputNumber) === 0) {
        setTokenError(true);
      } else {
        setTokenError(false);
      }
    }
    if (key === 'refreshTokenExpiry') {
      if (Number(inputNumber) === 0) {
        setRefreshTokenError(true);
      } else {
        setRefreshTokenError(false);
      }
    }
    if (inputNumber === '' || checkForNumber.test(inputNumber)) {
      setState({
        ...currentState,
        [key]: { ...currentState[key], value: Number(inputNumber) },
      });
    }
  };

  const discardFunction = (keys: ConfigurationPayloadKey[]): () => void => {
    return (): void => {
      if(state) {
        let newState: ConfigurationPayload = { ...state };
        keys.forEach((key) => {
          newState = {
            ...newState,
            [key]: configuration[key],
          }
        });
        setState(newState);
      }
    }
  };

  const {
    passwordAuthEnabled,
    userSignUpEnabled,
    googleAuthEnabled,
    facebookAuthEnabled,
    appleAuthEnabled,
    auth0AuthEnabled,
  } = configuration;

  let passwordAuthConfigurations;
  if (passwordAuthEnabled.value && state) {
    passwordAuthConfigurations = (
        <PasswordConfiguration
          className={classes['auth-method']}
          onChange={handleChangeConfiguration}
          configuration={state}
        />
    );
  }

  let googleAuthConfigurations;
  if (googleAuthEnabled.value && state) {
    const stateKeys: ConfigurationPayloadKey[] = [
      'googleAuthClientId',
      'googleAuthName',
      'googleAuthClientSecret',
    ];
    const isModified = isStateModified(state, configuration, stateKeys);
    const discardConfiguration = discardFunction(stateKeys);

    googleAuthConfigurations = (
        <GoogleConfiguration
          className={classes['auth-method']}
          configuration={state}
          onChangeInput={handleChangeInput}
          isModified={isModified}
          onSave={updateConfigurations}
          onDiscard={discardConfiguration}
        />
    );
  }

  let facebookAuthConfigurations;
  if (facebookAuthEnabled.value && state) {
    const stateKeys: ConfigurationPayloadKey[] = [
      'facebookAuthAppId',
      'facebookAuthName',
      'facebookAuthAppSecret',
    ];
    const isModified = isStateModified(state, configuration, stateKeys);
    const discardConfiguration = discardFunction(stateKeys);

    facebookAuthConfigurations = (
        <FacebookConfiguration
          className={classes['auth-method']}
          configuration={state}
          onChangeInput={handleChangeInput}
          isModified={isModified}
          onSave={updateConfigurations}
          onDiscard={discardConfiguration}
        />
    );
  }

  let appleAuthConfigurations;
  if (appleAuthEnabled.value && state) {
    const stateKeys: ConfigurationPayloadKey[] = [
      'appleAuthClientId',
      'appleAuthName',
      'appleAuthSigningKey',
      'appleAuthTeamId',
      'appleAuthSigningKeyId',
    ];
    const isModified = isStateModified(state, configuration, stateKeys);
    const discardConfiguration = discardFunction(stateKeys);

    appleAuthConfigurations = (
        <AppleConfiguration
          className={classes['auth-method']}
          configuration={state}
          onChangeInput={handleChangeInput}
          isModified={isModified}
          onSave={updateConfigurations}
          onDiscard={discardConfiguration}
        />
    );
  }

  let auth0AuthConfigurations;
  if(auth0AuthEnabled.value && state) {
    const stateKeys: ConfigurationPayloadKey[] = [
      'auth0AuthName',
      'auth0IssuerBaseUrl',
      'auth0AuthClientId',
      'auth0AuthClientSecret',
    ];
    const isModified = isStateModified(state, configuration, stateKeys);
    const discardConfiguration = discardFunction(stateKeys);

    auth0AuthConfigurations = (
      <Auth0Configuration 
        className={classes['auth-method']}
        configuration={state}
        onChangeInput={handleChangeInput}
        isModified={isModified}
        onSave={updateConfigurations}
        onDiscard={discardConfiguration}
      />
    );
  }

  let applicationUrlConfigurations;
  if (state) {
    const stateKeys: ConfigurationPayloadKey[] = [
      'signUpUrl',
      'resetPasswordUrl',
      'completionFormUrl',
      'ssoErrorUrl',
      'emailVerificationUrl',
    ];
    const isModified = isStateModified(state, configuration, stateKeys);
    const discardConfiguration = discardFunction(stateKeys);

    applicationUrlConfigurations = (
        <ApplicationUrlConfiguration
          configuration={state}
          onChangeInput={handleChangeInput}
          isModified={isModified}
          onSave={updateConfigurations}
          onDiscard={discardConfiguration}
        />
    );
  }

  let applicationTokenConfigurations;
  if (state) {
    const stateKeys: ConfigurationPayloadKey[] = [
      'tokenExpiry', 
      'refreshTokenExpiry',
    ];
    const isModified = isStateModified(state, configuration, stateKeys);
    const discardConfiguration = discardFunction(stateKeys);

    applicationTokenConfigurations = (
        <ApplicationTokenConfiguration
          configuration={state}
          onChangeInputNumber={handleChangeInputNumber}
          isModified={isModified}
          onSave={updateConfigurations}
          onDiscard={discardConfiguration}
          showTokenError={showTokenError}
          showRefreshTokenError={showRefreshTokenError}
        />
    );
  }

  return (
      <div className={classes.configuration}>
        <div className={classes.configuration__header__container}>
          <Text variant={Label.L1}>{t('authentication_methods')}</Text>
          <Button className={classes['add-auth__button']} onClick={onOpenModal}>
            <AddRounded />
            <span className={classes['add-auth__button__text']}>{t('add_authentication_button')}</span>
          </Button>
        </div>
        <div className={classes['auth-configurations']}>
          {passwordAuthConfigurations}
          {googleAuthConfigurations}
          {facebookAuthConfigurations}
          {appleAuthConfigurations}
          {auth0AuthConfigurations}
        </div>
        <div className={classes['user-signup']}>
          <Checkbox
            name='userSignUpEnabled'
            label={t('disable_user_signup')}
            checked={!userSignUpEnabled.value}
            onChange={(): void => handleChangeConfiguration('userSignUpEnabled', !userSignUpEnabled.value)}
          />
          <Text className={classes['user-signup__caption']} variant={Subtitle.S1}>
            {t('disable_user_signup_caption')}
          </Text>
        </div>
        {applicationUrlConfigurations}
        {applicationTokenConfigurations}
        <div className={classes['disable-identity__container']}>
          <Text variant={Heading.H6}>{t('disable_identity_service')}</Text>
          <Button outlined variant='danger'>
            {t('disable_identity_button')}
          </Button>
        </div>
      </div>
  );
};

export default Configuration;
