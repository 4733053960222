import { CheckCircleOutlineRounded } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classes from './ModalAuthenticationMethod.module.scss';
import Text, { Heading } from '../../atoms/Text';
import { Modal } from '../../molecules';
import AuthenticationMethod, { AuthenticationMethodType } from '../../molecules/AuthenticationMethod';
import { getAuthEnabledConfigurationValue, getEnabledMethods } from './utils';
import { ConfigurationPayload } from '../../../modules/configuration/types';

const ModalAuthenticationMethod: React.FC<{
  open: boolean;
  onClose: () => void;
  destinationUrl: string;
  loading: boolean;
  error?: string;
  configuration: ConfigurationPayload | null;
  updateConfigurations: (payload: Partial<ConfigurationPayload>) => Promise<void>;
  refetch: () => void;
}> = ({
  open,
  onClose,
  destinationUrl,
  loading,
  error,
  configuration,
  updateConfigurations,
  refetch,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <Modal loading open={open} onClose={onClose} />;
  }
  if (error || !configuration) {
    return <Modal open={open} onClose={onClose}>Error...</Modal>;
  }

  const handleSelectAuthMethod = async (method: AuthenticationMethodType): Promise<void> => {
    const configValue = getAuthEnabledConfigurationValue(method, configuration);
    await updateConfigurations(configValue);
    refetch();
    onClose();
  };

  const enabledMethods = getEnabledMethods(configuration);

  const authenticationMethods = Object.values(AuthenticationMethodType).map((
    method,
  ) => {
    const isMethodEnabled = !!enabledMethods.find((enabledMethod) => method === enabledMethod);
    let authMethodElement;
    if (isMethodEnabled) {
      authMethodElement = (
          <div key={method} className={classes['authentication-method--enabled']}>
            <AuthenticationMethod method={method} />
            <CheckCircleOutlineRounded />
          </div>
      );
    } else {
      authMethodElement = (
          <Link key={method} to={destinationUrl} className={classes['authentication-method__link']}>
            <AuthenticationMethod
              method={method}
              onClick={(): Promise<void> => handleSelectAuthMethod(method)}
            />
          </Link>
      );
    }
    return authMethodElement;
  });

  return (
      <Modal className={classes.modal} open={open} onClose={onClose}>
        <div className={classes['modal-content__container']}>
          <Text className={classes.title} variant={Heading.H4}>
            {t('add_authentication_modal_title')}
          </Text>
          {authenticationMethods}
        </div>
      </Modal>
  );
};

export default ModalAuthenticationMethod;
