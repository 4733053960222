import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import appleIcon from '../../../resources/images/apple.svg';
import facebookIcon from '../../../resources/images/facebook.svg';
import googleIcon from '../../../resources/images/google.svg';
import emailIcon from '../../../resources/images/email.svg';
import auth0Icon from '../../../resources/images/email.svg';

import classes from './AuthenticationMethod.module.scss';
import { Text } from '../../atoms';
import { Subtitle } from '../../atoms/Text';

export enum AuthenticationMethodType {
  password = 'password',
  google = 'google',
  facebook = 'facebook',
  apple = 'apple',
  auth0 = 'auth0',
}

const AuthenticationMethod: React.FC<{
  method: AuthenticationMethodType;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({
  method, className, onClick,
}) => {
  const { t } = useTranslation();

  let imgSrc;
  switch (method) {
    case 'password':
      imgSrc = emailIcon;
      break;
    case 'apple':
      imgSrc = appleIcon;
      break;
    case 'facebook':
      imgSrc = facebookIcon;
      break;
    case 'google':
      imgSrc = googleIcon;
      break;
    case 'auth0':
      imgSrc = auth0Icon;
      break;
    default:
      break;
  }

  return (
    <div
      className={classnames(classes['authentication-method'], className)}
      onClick={onClick}
    >
      <img src={imgSrc} alt={t(`${method}_icon_alt`)} />
      <Text variant={Subtitle.S1}>{t(`authentication_method_${method}`)}</Text>
    </div>
  );
};

export default AuthenticationMethod;
