import { ConfigurationPayload, ConfigurationPayloadKey } from '../../../modules/configuration/types';
import { AuthenticationMethodType } from '../../molecules/AuthenticationMethod';

// eslint-disable-next-line import/prefer-default-export
export const getAuthEnabledConfigurationValue = (
  authMethod: AuthenticationMethodType, configuration: ConfigurationPayload,
): Partial<ConfigurationPayload> => {
  const configValue: Partial<ConfigurationPayload> = {};
  switch (authMethod) {
    case AuthenticationMethodType.password:
      configValue.passwordAuthEnabled = {
        ...configuration.passwordAuthEnabled,
        value: true,
      };
      break;
    case AuthenticationMethodType.apple:
      configValue.appleAuthEnabled = {
        ...configuration.appleAuthEnabled,
        value: true,
      };
      break;
    case AuthenticationMethodType.google:
      configValue.googleAuthEnabled = {
        ...configuration.googleAuthEnabled,
        value: true,
      };
      break;
    case AuthenticationMethodType.facebook:
      configValue.facebookAuthEnabled = {
        ...configuration.facebookAuthEnabled,
        value: true,
      };
      break;
    case AuthenticationMethodType.auth0:
      configValue.auth0AuthEnabled = {
        ...configuration.auth0AuthEnabled,
        value: true,
      };
      break;
    default:
      break;
  }

  return configValue;
};

export const getEnabledMethods = (
  configuration: ConfigurationPayload,
): AuthenticationMethodType[] => {
  const enabledMethods: AuthenticationMethodType[] = [];
  Object.values(AuthenticationMethodType).forEach((method) => {
    const isMethodEnabled = configuration[`${method}AuthEnabled` as ConfigurationPayloadKey].value;
    if (isMethodEnabled) {
      enabledMethods.push(method);
    }
  });

  return enabledMethods;
};
