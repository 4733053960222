import React from 'react';
import classnames from 'classnames';
import { HelpOutlineRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../atoms';
import { InputChangeEvent } from '../../../../atoms/InputField/InputField';
import Text, { Caption } from '../../../../atoms/Text';
import { AuthenticationMethodType } from '../../../../molecules/AuthenticationMethod';
import AuthenticationMethodAccordion from '../AuthenticationMethodAccordion';

import classes from './FacebookConfiguration.module.scss';
import { FacebookAuthConfiguration, ConfigurationPayloadKey } from '../../../../../modules/configuration/types';

const FacebookConfiguration: React.FC<{
  // changed fields to app id and app secret
  configuration: FacebookAuthConfiguration;
  onChangeInput: (e: InputChangeEvent, key: ConfigurationPayloadKey) => void;
  className?: string;
  isModified?: boolean;
  onSave: (payload: Partial<FacebookAuthConfiguration>) => Promise<void>;
  onDiscard: () => void;
}> = ({
  configuration, onChangeInput, className, isModified = false, onSave, onDiscard,
}) => {
  const { t } = useTranslation();

  const handleSaveConfiguration = async (): Promise<void> => {
    const { facebookAuthAppId, facebookAuthName, facebookAuthAppSecret } = configuration;
    await onSave({
      facebookAuthAppId,
      facebookAuthName,
      facebookAuthAppSecret,
    });
  };

  return (
      <AuthenticationMethodAccordion
        className={classnames(className, classes['facebook-auth'])}
        method={AuthenticationMethodType.facebook}
        isModified={isModified}
        onSave={handleSaveConfiguration}
        onDiscard={onDiscard}
      >
        <div className={classes.input__container}>
          <TextField
            label={t('name')}
            value={configuration?.facebookAuthName.value || ''}
            onChange={(e: InputChangeEvent): void => onChangeInput(e, 'facebookAuthName')}
            placeholder='facebook'
          />
        </div>
        <div className={classes.input__container}>
          <TextField
            label={t('app_id')}
            value={configuration?.facebookAuthAppId.value || ''}
            onChange={(e: InputChangeEvent): void => onChangeInput(e, 'facebookAuthAppId')}
          />
          <Text className={classes['app-id__caption']} variant={Caption.C2}>
            <HelpOutlineRounded />
            {t('how_to_app_id')}
          </Text>
        </div>
        <div className={classes.input__container}>
          <TextField
            label={t('app_secret')}
            value={configuration?.facebookAuthAppSecret.value || ''}
            onChange={(e: InputChangeEvent): void => onChangeInput(e, 'facebookAuthAppSecret')}
          />
        </div>
      </AuthenticationMethodAccordion>
  );
};

export default FacebookConfiguration;
