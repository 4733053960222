import React from 'react';
import HomeRouter from './HomeRouter';
import Home from './Home';

const HomePage: React.FC<{}> = () => (
  <div>
    <Home />
    <HomeRouter />
  </div>
);

export default HomePage;
