import React from 'react';
import classnames from 'classnames';
import { HelpOutlineRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../atoms';
import { InputChangeEvent } from '../../../../atoms/InputField/InputField';
import Text, { Caption } from '../../../../atoms/Text';
import { AuthenticationMethodType } from '../../../../molecules/AuthenticationMethod';
import AuthenticationMethodAccordion from '../AuthenticationMethodAccordion';

import classes from './Auth0Configuration.module.scss';
import { Auth0AuthConfiguration, ConfigurationPayloadKey } from '../../../../../modules/configuration/types';

const Auth0Configuration: React.FC<{
  configuration: Auth0AuthConfiguration;
  onChangeInput: (e: InputChangeEvent, key: ConfigurationPayloadKey) => void;
  className?: string;
  isModified?: boolean;
  onSave: (payload: Partial<Auth0AuthConfiguration>) => Promise<void>;
  onDiscard: () => void;
}> = ({
  configuration, onChangeInput, className, isModified = false, onSave, onDiscard,
}) => {
  const { t } = useTranslation();

  const handleSaveConfiguration = async (): Promise<void> => {
    const { auth0IssuerBaseUrl, auth0AuthClientId, auth0AuthName, auth0AuthClientSecret } = configuration;
    await onSave({
      auth0IssuerBaseUrl,
      auth0AuthClientId,
      auth0AuthName,
      auth0AuthClientSecret,
    });
  };

  return (
    <AuthenticationMethodAccordion
      className={classnames(className, classes['auth0-auth'])}
      method={AuthenticationMethodType.auth0}
      isModified={isModified}
      onSave={handleSaveConfiguration}
      onDiscard={onDiscard}
    >
      <div className={classes.input__container}>
        <TextField
          label={t('name')}
          value={configuration?.auth0AuthName.value || ''}
          onChange={(e: InputChangeEvent): void => onChangeInput(e, 'auth0AuthName')}
          placeholder='auth0-oauth2'
        />
      </div>
      <div className={classes.input__container}>
        <TextField
          label={t('issuer_base_url')}
          value={configuration?.auth0IssuerBaseUrl.value || ''}
          onChange={(e: InputChangeEvent): void => onChangeInput(e, 'auth0IssuerBaseUrl')}
        />
      </div>
      <div className={classes.input__container}>
        <TextField
          label={t('client_id')}
          value={configuration?.auth0AuthClientId.value || ''}
          onChange={(e: InputChangeEvent): void => onChangeInput(e, 'auth0AuthClientId')}
        />
        <Text className={classes['client-id__caption']} variant={Caption.C2}>
          <HelpOutlineRounded />
          {t('how_to_client_id')}
        </Text>
      </div>
      <div className={classes.input__container}>
        <TextField
          label={t('client_secret')}
          value={configuration?.auth0AuthClientSecret.value || ''}
          onChange={(e: InputChangeEvent): void => onChangeInput(e, 'auth0AuthClientSecret')}
        />
      </div>
    </AuthenticationMethodAccordion>
  );
};

export default Auth0Configuration;
