import React from 'react';
import { Button as MUIButton, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import classes from './Button.module.scss';

// Adaptor component allowing MUI Button use React Router Link as its base component
type AdaptedLinkProps = {
  href: string;
};
const AdaptedLink = React.forwardRef<HTMLAnchorElement, AdaptedLinkProps>((
  { href, ...props },
  ref,
) => (<Link ref={ref} to={href} {...props} />));
AdaptedLink.displayName = 'AdaptedLink';

export type ButtonProps = {
  active?: boolean;
  value?: string | number;
  block?: boolean;
  children?: React.ReactNode | string;
  className?: string;
  disabled?: boolean;
  outlined?: boolean;
  ghost?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  type?: 'button' | 'reset' | 'submit';
  variant?: 'grey' | 'brand' | 'danger' | 'success' | 'warning' | 'info' | 'text';
  href?: string;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<any>) => void;
  startIcon?: React.ReactNode;
};

const Button: React.FC<ButtonProps> = ({
  active = false,
  block = false,
  children = null,
  className = '',
  disabled = false,
  outlined = false,
  ghost = false,
  size = 'lg',
  type = 'button',
  variant = 'brand',
  loading = false,
  href,
  onClick,
  startIcon,
  value,
}: ButtonProps) => {
  const composedClassName = classNames(
    classes.button,
    classes[variant],
    classes[size],
    {
      [classes.ghost]: ghost,
      [classes.outlined]: outlined,
      [classes.active]: active,
      [classes.disabled]: disabled,
      [classes.loading]: loading,
      [classes.block]: block,
    },
    className,
  );

  const buttonBaseComponent = href ? AdaptedLink : 'button';

  return (
    <MUIButton
      classes={{ root: composedClassName }}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      href={href}
      component={buttonBaseComponent}
      startIcon={startIcon}
      value={value}
    >
      {
        loading
          ? <CircularProgress className={classes.spinner} size={24} />
          : children
      }
    </MUIButton>
  );
};

export default Button;
