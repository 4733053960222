import useFetch from '../../../lib/useFetch';
import { getAccountInvitations, createOrUpdateAccountInvitation, deleteAccountInvitation } from './api';
import { AccountInvitation } from './types';

type UseAccountInvitationsResult = {
  loading: boolean;
  error: undefined | string;
  data: AccountInvitation[] | null;
  refetch: () => void;
  update: (payload: Partial<AccountInvitation>) => Promise<void>;
  remove: (id: number) => Promise<void>;
};

const useAccountInvitations = (): UseAccountInvitationsResult => {
  const state = useFetch<
  AccountInvitation[], Omit<AccountInvitation, 'id' | 'uuid'>, void
  >(getAccountInvitations, createOrUpdateAccountInvitation, deleteAccountInvitation);
  const update = state.update as (payload: Partial<AccountInvitation>) => Promise<void>;
  const remove = state.remove as (id: number) => Promise<void>;

  return { ...state, update, remove };
};

export default useAccountInvitations;
