import { AccountInvitation } from '../../../modules/account/invitation/types';
import { Account } from '../../../modules/account/types';

export const isAccountInvitation = (
  user: Account | AccountInvitation,
): user is AccountInvitation => (user as AccountInvitation).senderEmail !== undefined;

export const isAccountInvitationExpired = (
  userInvite: AccountInvitation,
): boolean => new Date(userInvite.expiry).getTime() < Date.now();
