import React, { useState, useEffect } from 'react';
import { Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Link, useHistory, useRouteMatch,
} from 'react-router-dom';
import { Tabs } from '../../../organisms';
import { Button, Menu } from '../../../atoms';
import Text, { Heading, Paragraph } from '../../../atoms/Text';
import { MenuButton } from '../../../molecules';
import { HomePageView, HomeRouterParams } from '../types';

import classes from './Home.module.scss';

const Home: React.FC<{}> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const { path, params } = useRouteMatch<HomeRouterParams>();
  const { appId, serviceName, tabName } = params;
  const url = `/${appId}/${serviceName}`;
  const [selectedTab, setSelectedTab] = useState(tabName || HomePageView.overview);

  useEffect(() => {
    if (tabName) {
      setSelectedTab(tabName);
    }
  }, [path, tabName]);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: HomePageView): void => {
    setSelectedTab(newValue);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const tabs = [
    { label: t('overview'), to: `${url}`, value: HomePageView.overview },
    { label: t('users'), to: `${url}/users`, value: HomePageView.users },
    { label: t('configuration'), to: `${url}/configuration`, value: HomePageView.configuration },
    { label: t('documentation'), to: `${url}/documentation`, value: HomePageView.documentation },
  ];

  const menuItems: { label: string; onClick: () => void; to: string }[] = [];
  const tabsElements: React.ReactNode[] = [];

  tabs.forEach(({ label, to, value }) => {
    menuItems.push({
      label,
      to,
      onClick: (): void => setMenuOpen(false),
    });

    tabsElements.push(
      <Tab
        key={label}
        label={<Link to={to}>{label}</Link>}
        classes={{ selected: classes.selected, root: classes.tab }}
        value={value}
      />,
    );
  });

  return (
    <div className={classes.home}>
      <Button
        className={classes['back-button']}
        variant='text'
        onClick={(): void => history.goBack()}
      >
        <Text variant={Paragraph.P2}>
          {t('back')}
        </Text>
      </Button>
      <div className={classes.title__container}>
        <div>
          <div className={classes.thumbnail} />
          <Text className={classes.title} variant={Heading.H5}>{t('identity')}</Text>
        </div>
        <MenuButton
          onClick={handleOpenMenu}
          className={classes['menu-button']}
          label={t(selectedTab)}
        />
      </div>
      <Menu
        className={classes.menu}
        open={menuOpen}
        anchorEl={anchorEl}
        items={menuItems}
        onClose={(): void => setMenuOpen(false)}
      />
      <Tabs className={classes.tabs} value={selectedTab} onChange={handleChangeTab} aria-label={t('identity_tabs_aria_label')}>
        {tabsElements}
      </Tabs>
    </div>
  );
};

export default Home;
