import React, { useState } from 'react';
import ModalAuthenticationMethod from '../ModalAuthenticationMethod';
import { useConfiguration } from '../../../modules/configuration';
import { ConfigurationPayload } from '../../../modules/configuration/types';

const ConfigurationContainer: React.FC<{
  component: React.ComponentType<{
    error?: string;
    configuration: ConfigurationPayload | null;
    updateConfigurations: (payload: Partial<ConfigurationPayload>) => Promise<void>;
    configurationUrl: string;
    onOpenModal: () => void;
  }>;
  path: string;
}> = ({ component: Component, path }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading,
    error,
    data: configuration,
    update: updateConfigurations,
    refetch,
  } = useConfiguration();

  return (
    <>
      <Component
        error={error}
        configuration={configuration}
        updateConfigurations={updateConfigurations}
        configurationUrl={path}
        onOpenModal={(): void => { setModalOpen(true); }}
      />
      <ModalAuthenticationMethod
        open={modalOpen}
        onClose={(): void => { setModalOpen(false); }}
        destinationUrl={path}
        loading={loading}
        error={error}
        configuration={configuration}
        updateConfigurations={updateConfigurations}
        refetch={refetch}
      />
    </>
  );
};

export default ConfigurationContainer;
