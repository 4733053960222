import React, { useState } from 'react';
import classnames from 'classnames';
import UserListItem from './UserListItem';
import UserMenu from './UserMenu';
import { UserStatus } from './types';
import { isAccountInvitation, isAccountInvitationExpired } from './utils';
import { Account } from '../../../modules/account/types';
import { AccountInvitation } from '../../../modules/account/invitation/types';

import classes from './UserList.module.scss';

const UserList: React.FC<{
  className?: string;
  users: Account[];
  userInvites: AccountInvitation[];
  selectedUser: Account | AccountInvitation | undefined;
  onSelectUser: (user: Account | AccountInvitation | undefined) => void;
  onResetPassword: () => void;
  onToggleEnabled: (enabled: boolean) => Promise<void>;
  onDeleteInvite: () => void;
  onResendInvite: () => void;
}> = ({
  className,
  users,
  userInvites,
  selectedUser,
  onSelectUser,
  onResetPassword,
  onResendInvite,
  onDeleteInvite,
  onToggleEnabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleCloseMenu = (): void => {
    setMenuOpen(false);
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>, user: Account | AccountInvitation,
  ): void => {
    setMenuOpen(true);
    setAnchorEl(e.currentTarget);
    onSelectUser(user);
  };

  const userList = users.map((user) => (
      <UserListItem
        className={classes['user-list-item']}
        {...user}
        onClick={(e): void => handleClick(e, user)}
        key={user.uuid}
      />
  ));

  const userInvitesList = userInvites.map((invite) => (
      <UserListItem
        className={classes['user-list-item']}
        {...invite}
        onClick={(e): void => handleClick(e, invite)}
        key={invite.id}
      />
  ));

  let status;
  let userMenuElement;
  if (selectedUser) {
    if (!isAccountInvitation(selectedUser)) {
      status = selectedUser.enabled ? UserStatus.enabled : UserStatus.disabled;
    } else if (isAccountInvitationExpired(selectedUser)) {
      status = UserStatus.expired;
    } else {
      status = UserStatus.pending;
    }

    userMenuElement = (
      <UserMenu
        anchorEl={anchorEl}
        status={status}
        open={menuOpen}
        onClose={handleCloseMenu}
        onResendInvite={onResendInvite}
        onResetPassword={onResetPassword}
        onDeleteInvite={onDeleteInvite}
        onToggleEnabled={onToggleEnabled}
      />
    );
  }

  return (
    <div className={classnames(className, classes['user-list'])}>
      {userList}
      {userInvitesList}
      {userMenuElement}
    </div>
  );
};

export default UserList;
