import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '../../../atoms';
import { UserStatus } from '../types';
import classes from './UserMenu.module.scss';

type UserMenuItem = {
  label: string;
  onClick: () => Promise<void> | void;
  className?: string;
};

const UserMenu: React.FC<{
  open: boolean;
  onClose: () => void;
  status: UserStatus;
  anchorEl: HTMLElement | null;
  onResetPassword: () => void;
  onToggleEnabled: (enabled: boolean) => Promise<void>;
  onDeleteInvite: () => void;
  onResendInvite: () => void;
}> = ({
  open,
  onClose,
  status,
  anchorEl,
  onResendInvite,
  onResetPassword,
  onDeleteInvite,
  onToggleEnabled,
}) => {
  const { t } = useTranslation();

  const handleToggleEnabled = async (enabled: boolean): Promise<void> => {
    await onToggleEnabled(enabled);
    onClose();
  };

  const handleResendInvite = (): void => {
    onResendInvite();
    onClose();
  };

  const handleDeleteInvite = (): void => {
    onDeleteInvite();
    onClose();
  };

  const handleResetPassword = (): void => {
    onResetPassword();
    onClose();
  };

  let items: UserMenuItem[] = [];
  switch (status) {
    case UserStatus.enabled:
      items = [
        { label: t('disable_user'), onClick: (): Promise<void> => handleToggleEnabled(false) },
        { label: t('reset_user_password'), onClick: handleResetPassword },
      ];
      break;
    case UserStatus.disabled:
      items = [
        { label: t('enable_user'), onClick: (): Promise<void> => handleToggleEnabled(true) },
      ];
      break;
    case UserStatus.pending:
      items = [
        {
          label: t('delete_invite'),
          onClick: handleDeleteInvite,
        },
      ];
      break;
    case UserStatus.expired:
      items = [
        { label: t('resend_invite'), onClick: handleResendInvite },
        { label: t('delete_invite'), onClick: handleDeleteInvite },
      ];
      break;
    default:
      break;
  }

  return (
    <Menu
      className={classes.menu}
      anchorEl={anchorEl}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      open={open}
      items={items}
      onClose={onClose}
    />
  );
};

export default UserMenu;
