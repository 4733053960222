import React from 'react';
import classnames from 'classnames';

import classes from './Text.module.scss';

export enum Paragraph {
  P1 = 'P1',
  P2 = 'P2',
}
export enum Caption {
  C1 = 'C1',
  C2 = 'C2',
}
export enum Subtitle {
  S1 = 'S1',
  S2 = 'S2',
}
export enum Label {
  L1 = 'L1',
}
export enum Heading {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
}

export enum Error {
  E1 = 'E1',
}

type TextVariant = Paragraph | Caption | Subtitle | Label | Heading | Error;

type TextProps = {
  variant?: TextVariant;
  className?: string;
  underlined?: boolean;
};

const variantClassMap: Record<TextVariant, string> = {
  [Paragraph.P1]: classnames(classes.paragraph, classes.p1),
  [Paragraph.P2]: classnames(classes.paragraph, classes.p2),
  [Caption.C1]: classnames(classes.caption, classes.c1),
  [Caption.C2]: classnames(classes.caption, classes.c2),
  [Subtitle.S1]: classnames(classes.subtitle, classes.s1),
  [Subtitle.S2]: classnames(classes.subtitle, classes.s2),
  [Label.L1]: classnames(classes.label, classes.l1),
  [Heading.H1]: classnames(classes.heading, classes.h1),
  [Heading.H2]: classnames(classes.heading, classes.h2),
  [Heading.H3]: classnames(classes.heading, classes.h3),
  [Heading.H4]: classnames(classes.heading, classes.h4),
  [Heading.H5]: classnames(classes.heading, classes.h5),
  [Heading.H6]: classnames(classes.heading, classes.h6),
  [Error.E1]: classnames(classes.error, classes.e1),
};

const Text: React.FC<TextProps> = ({
  variant = Paragraph.P1,
  className,
  children,
  underlined = false,
}) => {
  const variantClassName = variantClassMap[variant];

  const classNames = classnames(
    classes.text,
    variantClassName,
    className,
    underlined ? classes.underlined : '',
  );

  return (
    <span className={classNames}>
      {children}
    </span>
  );
};

export default Text;
