import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Text, TextField } from '../../../../atoms';
import { Label } from '../../../../atoms/Text';
import { InputChangeEvent } from '../../../../atoms/InputField/InputField';

import classes from './ApplicationTokenConfiguration.module.scss';
import { TokenConfiguration, ConfigurationPayloadKey } from '../../../../../modules/configuration/types';

const ApplicationUrlConfiguration: React.FC<{
  configuration: TokenConfiguration;
  onChangeInputNumber: (e: InputChangeEvent, key: ConfigurationPayloadKey) => void;
  isModified?: boolean;
  onSave: (payload: Partial<TokenConfiguration>) => Promise<void>;
  onDiscard: () => void;
  showTokenError: boolean;
  showRefreshTokenError: boolean;
}> = ({
  configuration,
  onChangeInputNumber,
  isModified = false,
  onSave,
  onDiscard,
  showTokenError,
  showRefreshTokenError,
}) => {
  const { t } = useTranslation();
  const handleSaveConfiguration = async (): Promise<void> => {
    const {
      tokenExpiry,
      refreshTokenExpiry,
    } = configuration;
    await onSave({
      tokenExpiry,
      refreshTokenExpiry,
    });
  };

  let buttons;

  if (isModified && !showTokenError && !showRefreshTokenError) {
    buttons = (
      <div className={classes.button__container}>
        <Button onClick={handleSaveConfiguration} size='sm'>{t('save')}</Button>
        <Button variant='grey' onClick={onDiscard} size='sm'>{t('discard')}</Button>
      </div>
    );
  }

  return (
    <div>
       <Text className={classes['application-tokens__label']} variant={Label.L1} underlined>
          {t('application_tokens')}
        </Text>
        <TextField
          className={classnames(classes['token-expiry__input'], classes.input)}
          onChange={(e: InputChangeEvent): void => onChangeInputNumber(e, 'tokenExpiry')}
          value={String(configuration?.tokenExpiry.value)}
          label={t('id_token_expiration')}
          errorComponent={'Token Expiry cannot be 0'}
          showError={showTokenError}
        />
        <TextField
          className={classnames(classes['refresh-token-expiry__input'], classes.input)}
          onChange={(e: InputChangeEvent): void => onChangeInputNumber(e, 'refreshTokenExpiry')}
          value={String(configuration?.refreshTokenExpiry.value)}
          label={t('refresh_token_lifetime')}
          errorComponent={'Refresh Token Lifetime cannot be 0'}
          showError={showRefreshTokenError}
        />

      {buttons}
    </div>
  );
};

export default ApplicationUrlConfiguration;
