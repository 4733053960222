import { IdentityServiceErrorResponse } from './types';
import { IDENTITY_API_URL, IS_DEV } from './config';

export type APIResponse<T> = T | IdentityServiceErrorResponse;

const serverUrlRegex = /^\/([a-zA-Z0-9]*)\/([a-zA-Z0-9-]*).?/i;

export const extractServiceDetails = (url: string): {
  appId?: string;
  serviceName?: string;
} => {
  const results = serverUrlRegex.exec(url);
  if (results) {
    const appId = results[1];
    const serviceName = results[2];
    return {
      appId,
      serviceName,
    };
  }
  return {};
};

export const getServerUrl = (): string => {
  if (IS_DEV) {
    return IDENTITY_API_URL;
  }

  const { appId, serviceName } = extractServiceDetails(window.location.pathname);
  return `${IDENTITY_API_URL}/${appId}/${serviceName}`;
};
