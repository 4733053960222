import React from 'react';
import classnames from 'classnames';
import { ExpandMoreRounded } from '@material-ui/icons';

import Button, { ButtonProps } from '../../atoms/Button/Button';
import Text, { Subtitle } from '../../atoms/Text';

import classes from './MenuButton.module.scss';

const MenuButton: React.FC<ButtonProps & {
  label: string | React.ReactElement; hideIcon?: boolean;
}> = ({
  className,
  label,
  hideIcon = false,
  ...props
}) => {
  let icon;
  if (!hideIcon) {
    icon = <ExpandMoreRounded />;
  }
  return (
    <Button {...props} className={classnames(classes['menu-button'], className)}>
      <Text variant={Subtitle.S2}>{label}</Text>
      {icon}
    </Button>
  );
};

export default MenuButton;
