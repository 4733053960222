import { AddRounded } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../atoms';

import classes from './Overview.module.scss';

const Overview: React.FC<{ onOpenModal: () => void }> = ({ onOpenModal }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.overview__container}>
      <Button onClick={onOpenModal}>
        <AddRounded />
        {t('add_authentication_button')}
      </Button>
    </div>
  );
};

export default Overview;
