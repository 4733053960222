import React from 'react';
import classnames from 'classnames';
import { Menu as MuiMenu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

import classes from './Menu.module.scss';

type MenuProps = {
  className?: string;
  items: {
    className?: string;
    label: string;
    onClick?: () => void | Promise<void>;
    to?: string;
  }[];
  open: boolean;
  onClose: () => void;
  anchorEl?: HTMLElement | null;
  transformOrigin?: {
    horizontal: 'center' | 'left' | 'right'| number;
    vertical: 'bottom' | 'center' | 'top' | number;
  };
};

const Menu: React.FC<MenuProps> = ({
  className,
  items,
  onClose,
  open,
  anchorEl,
  transformOrigin,
}) => {
  const menuItems = items.map((item) => {
    const {
      to, label, onClick, className: itemClassName,
    } = item;
    const labelElement = to ? <Link className={classes.menu__link} to={to}>{label}</Link> : label;

    return (
      <MenuItem
        className={classnames(classes['menu-item'], itemClassName)}
        onClick={onClick}
        key={label}
      >
        {labelElement}
      </MenuItem>
    );
  });
  return (
    <MuiMenu
      className={className}
      anchorEl={anchorEl}
      onClose={onClose}
      transformOrigin={transformOrigin}
      open={open}>
      {menuItems}
    </MuiMenu>
  );
};

export default Menu;
