import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  CancelOutlined, CheckCircleOutlineRounded, ExpandMoreRounded, MoreVert, AccessTime as Pending,
} from '@material-ui/icons';
import Text, { Paragraph, Subtitle } from '../../../atoms/Text';
import MenuButton from '../../MenuButton';
import { isAccountInvitation, isAccountInvitationExpired } from '../utils';
import { UserStatus } from '../types';
import { Account } from '../../../../modules/account/types';
import { AccountInvitation } from '../../../../modules/account/invitation/types';

import classes from './UserListItem.module.scss';

export type UserListItemProps = (Account | AccountInvitation) & {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const UserListItem: React.FC<UserListItemProps> = ({
  className, onClick, ...user
}) => {
  let nameElement;
  let iconElementDesktop;
  let labelKey;
  if (!isAccountInvitation(user)) {
    const {
      firstName, lastName, enabled,
    } = user;

    nameElement = (
      <Text variant={Subtitle.S1}>{`${firstName} ${lastName}`}</Text>
    );

    labelKey = enabled ? UserStatus.enabled : UserStatus.disabled;

    iconElementDesktop = enabled
      ? <CheckCircleOutlineRounded className={classes.checkmark} />
      : <CancelOutlined className={classes.cancel} />;
  } else if (!isAccountInvitationExpired(user)) {
    labelKey = UserStatus.pending;
    iconElementDesktop = <Pending className={classes.pending} />;
  } else {
    labelKey = UserStatus.expired;
    iconElementDesktop = <Pending className={classes.expired} />;
  }

  const { email } = user;
  const { t } = useTranslation();

  const classNames = classnames(
    className,
    classes['user-list-item'],
  );

  const labelIconMobile = <MoreVert />;
  const label = (
    <>
      <span className={classes['button__label--mobile']}>
        {labelIconMobile}
      </span>
      <span className={classes['button__label--desktop']}>
        {iconElementDesktop}
        {t(labelKey)}
        <ExpandMoreRounded />
      </span>
    </>
  );

  return (
    <div className={classNames}>
      <div className={classes['user-list-item__info-container']}>
        {nameElement}
        <Text className={classes['user-list-item__email-text']} variant={Paragraph.P2}>
          {email}
        </Text>
      </div>
      <MenuButton hideIcon className={classes['menu-button']} label={label} onClick={onClick} />
    </div>
  );
};

export default UserListItem;
