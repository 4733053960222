import React from 'react';
import classes from './AppLayout.module.scss';

const AppLayout: React.FC<{}> = ({ children }) => (
  <div className={classes['app-layout']}>
    {children}
  </div>
);

export default AppLayout;
