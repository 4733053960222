import useFetch from '../../lib/useFetch';
import { getConfiguration, updateConfiguration } from './api';
import { ConfigurationPayload } from './types';

type UseConfigurationResult = {
  loading: boolean;
  error: undefined | string;
  data: ConfigurationPayload | null;
  refetch: () => void;
  update: (payload: Partial<ConfigurationPayload>) => Promise<void>;
};

const useConfiguration = (): UseConfigurationResult => {
  const state = useFetch<ConfigurationPayload>(
    getConfiguration, updateConfiguration,
  );
  const update = state.update as (payload: Partial<ConfigurationPayload>) => Promise<void>;
  return { ...state, update };
};

export default useConfiguration;
