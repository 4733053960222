import React from 'react';
import classnames from 'classnames';
import { HelpOutlineRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../atoms';
import { InputChangeEvent } from '../../../../atoms/InputField/InputField';
import Text, { Caption } from '../../../../atoms/Text';
import { AuthenticationMethodType } from '../../../../molecules/AuthenticationMethod';
import AuthenticationMethodAccordion from '../AuthenticationMethodAccordion';

import classes from './GoogleConfiguration.module.scss';
import { GoogleAuthConfiguration, ConfigurationPayloadKey } from '../../../../../modules/configuration/types';

const GoogleConfiguration: React.FC<{
  configuration: GoogleAuthConfiguration;
  onChangeInput: (e: InputChangeEvent, key: ConfigurationPayloadKey) => void;
  className?: string;
  isModified?: boolean;
  onSave: (payload: Partial<GoogleAuthConfiguration>) => Promise<void>;
  onDiscard: () => void;
}> = ({
  configuration, onChangeInput, className, isModified = false, onSave, onDiscard,
}) => {
  const { t } = useTranslation();

  const handleSaveConfiguration = async (): Promise<void> => {
    const { googleAuthClientId, googleAuthName, googleAuthClientSecret } = configuration;
    await onSave({
      googleAuthClientId,
      googleAuthName,
      googleAuthClientSecret,
    });
  };

  return (
    <AuthenticationMethodAccordion
      className={classnames(className, classes['google-auth'])}
      method={AuthenticationMethodType.google}
      isModified={isModified}
      onSave={handleSaveConfiguration}
      onDiscard={onDiscard}
    >
      <div className={classes.input__container}>
        <TextField
          label={t('name')}
          value={configuration?.googleAuthName.value || ''}
          onChange={(e: InputChangeEvent): void => onChangeInput(e, 'googleAuthName')}
          placeholder='google-oauth2'
        />
      </div>
      <div className={classes.input__container}>
        <TextField
          label={t('client_id')}
          value={configuration?.googleAuthClientId.value || ''}
          onChange={(e: InputChangeEvent): void => onChangeInput(e, 'googleAuthClientId')}
        />
        <Text className={classes['client-id__caption']} variant={Caption.C2}>
          <HelpOutlineRounded />
          {t('how_to_client_id')}
        </Text>
      </div>
      <div className={classes.input__container}>
        <TextField
          label={t('client_secret')}
          value={configuration?.googleAuthClientSecret.value || ''}
          onChange={(e: InputChangeEvent): void => onChangeInput(e, 'googleAuthClientSecret')}
        />
      </div>
    </AuthenticationMethodAccordion>
  );
};

export default GoogleConfiguration;
