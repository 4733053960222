import React from 'react';
import { IconButton, Modal as MuiModal, CircularProgress } from '@material-ui/core';
import CloseRounded from '@material-ui/icons/CloseRounded';

import classes from './Modal.module.scss';

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  className?: string;
  loading?: boolean;
};

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  children,
  className,
  loading = false,
}) => {
  const modalContents = loading ? <CircularProgress className={classes.loading__icon} /> : children;
  return (
  <MuiModal className={className} open={open} onClose={onClose} onBackdropClick={onClose}>
    <div className={classes['modal__content-wrapper']}>
      <div className={classes.modal__content}>
        <IconButton className={classes['modal__close-button']} onClick={onClose}>
          <CloseRounded />
        </IconButton>
        {modalContents}
      </div>
    </div>
  </MuiModal>
  );
};

export default Modal;
